import governance from "./images/svg/governance.svg";
import financial from "./images/svg/financial.svg";
import agric from "./images/svg/agric.svg";
import health from "./images/svg/health.svg";
import lifestyle from "./images/svg/lifestyle.svg";
import education from "./images/svg/education.svg";
import digital from "./images/svg/digital.svg";
import Pic1 from "./images/book/pic1.png";
import Pic2 from "./images/book/pic2.png";
import Pic3 from "./images/book/pic3.png";
import Pic4 from "./images/book/pic4.png";
import Pic5 from "./images/book/pic5.png";
import Pic6 from "./images/book/pic6.png";
import Pic7 from "./images/book/pic7.png";
import Pic8 from "./images/book/pic8.png";
import Pic9 from "./images/book/pic9.png";
import Pic10 from "./images/book/pic10.png";
import Pic11 from "./images/book/pic11.png";
import Pic12 from "./images/book/pic12.png";
import Pic13 from "./images/book/pic13.png";
import Pic14 from "./images/book/pic14.png";
import Pic15 from "./images/book/pic15.png";
import Pic16 from "./images/book/pic16.png";
import Customer1 from "./images/book/avatar.png";

export const values = [
  {
    id: "01",

    title: "Funding",
    text: "With our network of investors and funding system, we provide start-ups with funds, grants and investment opportunities to help them scale the development of their idea.",
  },
  {
    id: "02",
    title: "Support and Mentorship",
    text: "Mentorship is a valuable tool for turning your idea into reality. We have mentors and coaches that contribute to providing guidance and mentorship for start-ups in our community.",
  },
  {
    id: "03",
    title: "Networking",
    text: "Widen your circle as you engage and rub minds with other professionals in our community to stimulate your idea and grow your enterprise rapidly.",
  },
  {
    id: "04",
    title: "Marketing Strategy",
    text: "We help you map out a long-term overall plan for your idea with the goal of achieving sustainability in the market. Locate and reach your target audience fast with proven principles and strategies.",
  },
  {
    id: "05",
    title: "Co-Working Space",
    text: "Access to our beautifully designed physical space that improves your creativity and enable your ideas thrive and come to life all at no cost.",
  },
  {
    id: "06",
    title: "MVP Testing",
    text: "We assist in creating a minimum viable product (MVP) that gives you the opportunity to test your product concept and determine the validity or invalidity of the strategy in the market.",
  },
];

export const areas = [
  {
    id: 1,
    duration: 1000,
    bgcolor: "#EBFCE7",
    title: "Financial Tech",
    icon: financial,
    text: "Ideas that help enterprises, business owners and consumers better control and manage their financial operations, activities, and lives by utilizing specialized software and digital innovations. ",
  },
  {
    id: 2,

    bgcolor: "#E8FAF5",
    title: "Agriculture",
    icon: agric,
    text: "All industries around the world have been invaded by technological advances and agriculture is no exception. We believe that new innovations in tech will revolutionize agriculture and result in a positive impact on the bottom line of farmers and growers in several ways. ",
  },
  {
    id: 3,
    bgcolor: "#FEFAE6",
    title: "Lifestyle  & Entertainment",
    icon: lifestyle,
    text: "The entertainment industry highly depends on technology. Every piece of musical or visual art needs to be captured by specific equipment designed for that job. For the customers to get access to the art, they have to find the right device to play or watch it. Ideas on better methods of capturing and showcasing entertainment are welcomed.",
  },
  {
    id: 4,
    bgcolor: "#DDEFF4",
    title: "Governance",
    icon: governance,
    text: "Fast-pace innovations and short product cycles require some words continuosly delivery of technology products and merearchitectural plug & play together excellence",
  },
  {
    id: 5,
    bgcolor: "#EDE8FA",
    title: "Health Tech",
    icon: health,
    text: "Digital technology will help transform unsustainable healthcare systems into sustainable ones. Health trackers, wearables and sensors are perfect tools for getting to know more about ourselves and taking charge of our own lives again. With more tech innovations in healthcare, we will have more healthy people in our society",
  },
  {
    id: 6,
    bgcolor: "#FAE9E8",
    title: "Education",
    icon: education,
    text: "Innovations in the educational sector that create digital learning tools and devices which help build 21st century skills, supports learning 24 hours a day, increases student engagement and motivation and ultimately accelerates learning in the nation.",
  },
  {
    id: 7,
    bgcolor: "#EBF9FC",
    icon: digital,
    title: "Digital Security",
    text: "The protection of one's digital personality is Digital Security, as it reflects the physical identity of the network on which you operate or the internet service in use. There are quite a number of cyber-attacks, cyber-theft, hack or crime everyday. What role can you play in putting proper digital security systems in place to make Nigeria a better place to live in?",
  },
];

export const Images = [
  { id: 1, image: Pic1 },
  { id: 2, image: Pic2 },
  { id: 3, image: Pic3 },
  { id: 4, image: Pic4 },
  { id: 5, image: Pic5 },
  { id: 6, image: Pic6 },
  { id: 7, image: Pic7 },
  { id: 8, image: Pic8 },
  { id: 9, image: Pic9 },
  { id: 10, image: Pic10 },
  { id: 11, image: Pic11 },
  { id: 12, image: Pic12 },
  { id: 13, image: Pic13 },
  { id: 14, image: Pic14 },
  { id: 15, image: Pic15 },
  { id: 16, image: Pic16 },
];

export const Testimony = [
  {
    id: 1,
    text: "With Grazac co-working space, you can get the best experience, be it in power supply, or internet connection. They only deliver a top-notch experience",
    // image: Customer1,
    name: "Oyerinde Samuel Abiodun",
    // skill: "Frontend Developer",
  },
  {
    id: 2,
    text: "The co-working space is amazing, the internet speed is fast and very reliable. The fact that this workspace is solar-powered means I get to enjoy a peaceful and quiet atmosphere when I work.",
    // image: Customer1,
    name: "Alao Abiodun",
    // skill: "UX Designer",
  },
  {
    id: 3,
    text: "As a remote freelancer, my work causes me to use a lot of co-working space across the south-west region and I must say, my experience here is one, I’d love to have again and again. Definitely 5-star service.",
    // image: Customer1,
    name: "Temitope Olubiire",
    // skill: "UX Designer",
  },
  {
    id: 4,
    text: "I had a client meeting here last time and I must commend the wifi connectivity. It was a seamless experience!!",
    // image: Customer1,
    name: "Ayodeji Igbinola",
    // skill: "UX Designer",
  },
  {
    id: 5,
    text: "Great place, with great people, an amazing workspace conducive for business. It feels like my personal space indeed.",
    // image: Customer1,
    name: "Olukorede Saheed",
    // skill: "UX Designer",
  },
];
